
import { defineComponent } from "vue";

export default defineComponent({
  name: "SuperAdminPanelNavMenu",
  data() {
    return {};
  },
  components: {},
  methods: {},
});
