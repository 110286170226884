
import { defineComponent } from "vue";
import SuperAdminPanelNavMenu from "@/components/molecules/SuperAdminPanelNavMenu.vue";
import Pagination from "@/components/molecules/Pagination.vue";
import View from "@/assets/svg/view.svg?inline";
import Edit from "@/assets/svg/edit.svg?inline";
import Delete from "@/assets/svg/delete.svg?inline";
import Pluse from "@/assets/svg/pluse.svg?inline";
import Search from "@/assets/svg/search.svg?inline";
import SortArrow from "@/assets/svg/sort-arrow.svg?inline";
import CheckFinancialManager from "@/assets/svg/check-financial-manager.svg?inline";
import DefaultPopup from "@/components/molecules/DefaultPopup.vue";
import AlertBlock from "@/components/molecules/AlertBlock.vue";

export default defineComponent({
  name: "SchoolAdminList",
  data() {
    return {
      page: 1,
      adminsData: {},
      adminsMeta: {},
      errorAdmin: null,
      searchData: "",
      adminlId: null,
      isModalDelete: false,
    };
  },
  components: {
    SuperAdminPanelNavMenu,
    Pagination,
    View,
    Edit,
    Delete,
    Pluse,
    Search,
    CheckFinancialManager,
    SortArrow,
    DefaultPopup,
    AlertBlock,
  },
  watch: {
    searchData() {
      this.getAdminsList();
      this.page = 1;
    },
  },
  mounted() {
    this.getAdminsList();
  },
  methods: {
    getAdminsList(params, sort) {
      let searchParam = `?page=${this.page}&search=`;
      if (this.searchData !== "") {
        searchParam = `?page=${this.page}&search=` + this.searchData;
      }
      if (sort === undefined) {
        sort = "";
      }
      params = searchParam + "&sort=" + sort;
      this.$store.dispatch("admins/getAdminsList", params).then(
        (res) => {
          this.adminsData = res.data;
          this.adminsMeta = res.meta;
        },
        (error) => {
          this.errorAdmins = error.response.data.errors;
        }
      );
    },
    handlePage(page) {
      this.page = page;
      this.getAdminsList();
    },
    createSchool() {
      this.$router.push("/super-admin/create-school-admin");
    },
    editSchool(id) {
      this.$router.push(
        "/super-admin/create-school-admin?edit=true&admin=" + id
      );
    },
    deleteAdmin() {
      this.$store.dispatch("admins/deleteAdmin", this.adminlId).then(
        () => {
          this.getAdminsList();
          this.isModalDelete = false;
          this.adminlId = null;
        },
        (error) => {
          this.errorAdmin = error.response.data;
        }
      );
    },
    viewSchool(id) {
      this.$router.push("/super-admin/admin?admin=" + id);
    },
    openDeletePoup(id) {
      this.adminlId = id;
      this.isModalDelete = true;
    },
    handleCloseModalDelete() {
      this.isModalDelete = false;
      this.adminlId = null;
      this.errorAdmin = null;
    },
  },
});
